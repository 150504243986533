/** @jsxImportSource @emotion/react  */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import usericon from "./usericon.png";
import axios from "axios";
import { useLocation } from "react-router-dom";

const submitURL = process.env.REACT_APP_SUBMIT_URL || "";
const getmaxdrinknumURL = process.env.REACT_APP_GET_MAX_DRINK_NUM_URL || "";

const User = () => {

  const  question1 = process.env.REACT_APP_QUESTION1 || "";
  const  question2 = process.env.REACT_APP_QUESTION2 || "";
  const  question3 = process.env.REACT_APP_QUESTION3 || "";
  const  question4 = process.env.REACT_APP_QUESTION4 || "";
  const  question5 = process.env.REACT_APP_QUESTION5 || "";
  const  question6 = process.env.REACT_APP_QUESTION6 || "";
  const  question7 = process.env.REACT_APP_QUESTION7 || "";
  
  //2023-08-21 追加　ここから
  const user_type = process.env.REACT_APP_USER_TYPE || "";
  const product_ticket_id = process.env.REACT_APP_PRODUCT_TICKET_ID || "";
  const customer_store_id = process.env.REACT_APP_CUSTOMER_STORE_ID || "";
  const user_id = process.env.REACT_APP_USER_ID || "";
  const expire_month = process.env.REACT_APP_EXPIRE_MONTH || "";
  //2023-08-21 追加　ここまで

    
  const navigate = useNavigate();

  const [current_max_drink_num, setTextCurrent] = useState(""); 

  let form_data = {
    form_id: process.env.REACT_APP_EVENT_ID || ""
  };

  useEffect(() => {
    /* console.log("fetch" + JSON.stringify(form_data)); */

    axios
    .post(getmaxdrinknumURL, form_data)
      .then((response) => {
        /* console.log("response: " + JSON.stringify(response)); */
        let result = response.data["Items"][0]["max_drink_num"]
        /* console.log(result) */
        setTextCurrent(result);
      })
      .catch((err) => {
        console.log(err);
      });
    //    }, [data]);
  }, []);


  const toQrcode = () => {
      //フォームデータをAWSに送信し、OKならばqrcodeページに遷移する
      let form = { form_id: process.env.REACT_APP_EVENT_ID || "" };
      //静的です。修正必要。
      let form_data = {
        form_id: process.env.REACT_APP_EVENT_ID || "",
        drink_num: current_max_drink_num,
        question1: question1,
        question2: question2,
        question3: question3,
        question4: question4,
        question5: question5,
        question6: question6,
        question7: question7,
  //2023-08-21 追加　ここから
        user_type: user_type,
        product_ticket_id: product_ticket_id,
        customer_store_id: customer_store_id,
        user_id: user_id,
        expire_month: expire_month,
  //2023-08-21 追加　ここまで
      };

      axios
        .post(submitURL, form_data)
        .then((response) => {
          console.log("response: " + JSON.stringify(response));
          console.log("user_id" + response.data.body.user_id);
          let form_id = response.data.body.form_id;
          let user_id = response.data.body.user_id;
          let eka_data = response.data.body.eka_data;
          navigate("/qrcode", {state: { form_id: form_id, user_id: user_id, eka_data: eka_data },});
        })
        .catch((error) => {
          console.error(error);
      });
  }
    
  /* console.log(current_max_drink_num) */
 
  return (
    <div>
      <h1>設定状況</h1>
      <Box
        sx={{
          m: 3,
          p: 1,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 1,
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
      >
       
        <div css={usertitle}>
          <img src={usericon} css={userIcon} alt="usericon" />User
        </div>
      </Box>
      <p css={center}>
          <h1>現在の杯数上限</h1>
          <h1>{current_max_drink_num}　杯</h1>
          <br />
          上記を変更する場合は、
          <br />
          管理者へお問い合わせください。
        </p>

      <Stack
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Button variant="contained" color="primary" onClick={toQrcode} >
          QRコード生成
        </Button>
      </Stack>
    
    </div>
  );
};

const userIcon = css`
  text-align: left;
  margin: auto;
  width: 4rem;
  height:4rem;
  max-width:100%;
  max-height:100%;
  pointer-events: none;
  vertical-align: middle;
`;

const appLogo = css`
  margin: auto;
  height: 130vmin;
  pointer-events: none;
`;

const topmargin = css`
  margin-top: 3.5rem;
`;

const center = css`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: center;
  font-size: 0.85rem;
  font-weight: 700;
`;

const left = css`
  text-align: left;
  margin: 0 1rem 0 1rem;
  font-size: 0.85rem;
`;

const usertitle = css`
  font-size: 2rem;
  color : #A31E2E;
`;


export default User;

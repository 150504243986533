/** @jsxImportSource @emotion/react  */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { css } from "@emotion/react";
import axios from "axios";
import qrheader from "./qrheader.png";
import qrfooter from "./qrfooter.png";


const getmaxdrinknumURL = process.env.REACT_APP_GET_MAX_DRINK_NUM_URL || "";

const Eka = () => {
  const location = useLocation();
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState("0");
  const [id, setId] = useState<{
    form_id: string;
    user_id: string;
    eka_data: string;
  }>(location.state as { form_id: string; user_id: string; eka_data: string });
  let nowdate = new Date();

  const [current_max_drink_num, setTextCurrent] = useState(""); 

  let form_data = {
    form_id: process.env.REACT_APP_EVENT_ID || ""
  };

  useEffect(() => {
    /* console.log("fetch" + JSON.stringify(form_data)); */
    // アンケートIDがある場合は、アンケートIDを元にQRを取得する

    axios
    .post(getmaxdrinknumURL, form_data)
      .then((response) => {
         /* console.log("response: " + JSON.stringify(response)); */
        let result = response.data["Items"][0]["max_drink_num"]
         /* console.log(result) */
        setTextCurrent(result);
      })
      .catch((err) => {
        console.log(err);
      });
    //    }, [data]);
  }, []);


  // curl -X POST -H "Content-Type: application/json" -d '{"tag_id": "0001"}' https://31ruz4oapc.execute-api.ap-northeast-1.amazonaws.com/test_generate/get/
  // 2023-08-21 コメントアウト　QRコード生成APIGW（旧）
  //const url =
  //  "https://mzskclw0m6.execute-api.ap-northeast-1.amazonaws.com/dev/get/";
  // 2023-08-21 追加　QRコード生成APIGW（新御酒飲帳風、取り込み機能あり）
  const url = 
    "https://bpzydopnsi.execute-api.ap-northeast-1.amazonaws.com/dev/get";
  //const url =
  //  "https://31ruz4oapc.execute-api.ap-northeast-1.amazonaws.com/test_generate/get/";

  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";

  useEffect(() => {
    console.log("fetch" + JSON.stringify(id));
    // アンケートIDがある場合は、アンケートIDを元にQRを取得する
    let user_id = id.form_id + "-" + id.user_id;
    let eka_data = {
      tag_id: "error_tag",
      group: "error_grp",
    };
    if (id.eka_data) {
      let eka_body = JSON.parse(id.eka_data);
      eka_data = {
        tag_id: eka_body.id,
        group: eka_body.gp,
      };
    }
    const currentHour = new Date();
    /* console.log("tag_id: " + JSON.stringify(eka_data)); */
    /*const data = {
      tag_id: id.eka_id,
      group: id.eka_id,
    };*/

    axios
      .post(url, eka_data)
      .then((response) => {
        setImgSrc("data:image/svg+xml;base64," + response.data.body);
      })
      .catch((err) => {
        console.log(err);
      });
    //    }, [data]);
  }, []);

  return (
    <div className="Eka">

        <p css={eka_center}>
          <div>
           <img src={qrheader} css={qrHeader} alt="header" />
          </div>
        {imgSrc && (
        <div>
            <img src={imgSrc} css={qr_code} alt="QR Code" />
          </div>
        )}
          <h1>{current_max_drink_num}　杯</h1>
        <div>
           
        </div>
        <p css={parameta}>発行日時:{nowdate.toLocaleString('ja-JP')}</p>
        <Button variant="outlined" color="inherit" component={Link} to="/user" >
           印刷画面に戻る
       </Button>
        </p>
    </div>
  );
};

const eka_center = css`
  text-align: center;
  background-color: white;
  color:#000000;
`;

const parameta= css`
  text-align: center;
  font-size: 1rem;
`;

const qr_box = css`
  margin-top: 2rem;
`;
const qr_code = css`
  display: block;
  max-width:45%;
  margin: auto;
`;

const drunk_st = css`
  font-size: 2.5rem;
`;

const drunk_num = css`
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 1rem;
`;

const more_drunk_btn = css`
  width: 90%;
  display: block;
  margin: auto;
  text-align: center;
  background-color: #ea4848;
  color: #fff;
`;

const dialogt_style = css`
  color: black;
  text-align: center;
  font-size: 1.3rem;
`;

const qrHeader = css`
  text-align: center;
  margin: auto;
  height:auto;
  max-width:45%;
  max-height:45%;
  pointer-events: none;
`;

const qrFooter = css`
  margin: auto;
  height:auto;
  max-width:45%;
  max-height:45%;
  pointer-events: none;
`;

export default Eka;

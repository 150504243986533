import { Calculate } from "@mui/icons-material";
import { createTheme } from "@mui/material";

const nomasse = createTheme({
  palette: {
    background: {
      default: '#A31E2E',
    },
    text: {
      primary: '#FFFFFF',
    },
    primary: {  // MUI
      main: '#FFFFFF',
      contrastText: '#A31E2E',
    }
  },
});

export default nomasse;
/** @jsxImportSource @emotion/react  */
import { HashRouter, Routes, Route } from 'react-router-dom';

import { Global, css } from "@emotion/react";


import Qrcode from './Eka';
import User from './User';

const App = () => {
    return (
        /*
        <HashRouter>
            <Global styles={global} />
            <div css={appCss}>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="form" element={<Form/>} />
                    <Route path="user" element={<User/>} />
                    <Route path="admin" element={<Admin/>} /> 
                    <Route path="complete" element={<Complete/>} />
                    <Route path="qrcode" element={<Qrcode/>} />
                    <Route path="qrcode_admin" element={<QrcodeAdmin/>} />
                    <Route path="about" element={<About/>} />
                </Routes>
            </div>
        </HashRouter>
    )

    */
       
            <HashRouter>
                <Global styles={global} />
                <div css={appCss}>
                    <Routes>
                        <Route path="/" element={<User/>} />
                        <Route path="user" element={<User/>} /> 
                        <Route path="qrcode" element={<Qrcode/>} />
                    </Routes>
                </div>
            </HashRouter>

        );
}

const global = css`
    h1 {
        font-size: calc(16px + 2vmin);
    }
    h2 {
        font-size: calc(14px + 2vmin);
    }
    footer {
        padding-top: 4%;
        padding-bottom: 4%;
        background-color: white;
        text-align: center;
        color: black;
    }
`;

const appCss = css`
    padding-bottom: 20px;
`;

export default App;
